var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.chapterPages, function (chapterPage) {
        return [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: chapterPage[_vm.defaultTitle] == _vm.activeChapterPage,
                  expression:
                    "(chapterPage[defaultTitle] == activeChapterPage)",
                },
              ],
              key: chapterPage[_vm.defaultTitle],
              staticClass: "journal-chapter",
            },
            [
              _c("div", { staticClass: "journal-page page-left" }, [
                _c("div", { staticClass: "pages-content" }, [
                  _c("div", { staticClass: "chapter-portrait-wrapper" }, [
                    _c("img", {
                      staticClass: "chapter-portrait flipped",
                      class:
                        "chapter-portrait-" + chapterPage[_vm.defaultTitle],
                      attrs: { src: _vm.getLargeImg(chapterPage) },
                    }),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "journal-page page-right" }, [
                _c("div", { staticClass: "pages-content" }, [
                  _c("div", { staticClass: "content-inner" }, [
                    _c("h2", [_vm._v(_vm._s(chapterPage[_vm.headerTitle]))]),
                    chapterPage.extraInfo
                      ? _c("p", {
                          domProps: {
                            innerHTML: _vm._s(chapterPage.extraInfo),
                          },
                        })
                      : _vm._e(),
                    _c("p", {
                      staticClass: "text",
                      domProps: { innerHTML: _vm._s(chapterPage.description) },
                    }),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      }),
      _c(
        "div",
        {
          staticClass: "grid chapters-grid",
          class: "chapters-grid-" + _vm.chapterPages.length,
        },
        _vm._l(_vm.chapterPages, function (bookmark) {
          return _c(
            "div",
            {
              key: bookmark[_vm.defaultTitle],
              class: [
                "hexagon avatar_small chapter-hexagon grayscale",
                {
                  active: bookmark[_vm.defaultTitle] == _vm.activeChapterPage,
                },
              ],
              on: {
                click: function ($event) {
                  return _vm.selectPage(bookmark[_vm.defaultTitle])
                },
              },
            },
            [
              _c("div", { staticClass: "hexagon-in1" }, [
                _c("div", {
                  staticClass: "hexagon-in2",
                  style: {
                    "background-image":
                      "url(" + _vm.getSmallImg(bookmark) + ")",
                  },
                }),
              ]),
            ]
          )
        }),
        0
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
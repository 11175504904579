<template>
  <div>
    <template v-for="chapterPage in chapterPages">
      <div
        v-show="(chapterPage[defaultTitle] == activeChapterPage)"
        :key="chapterPage[defaultTitle]"
        class="journal-chapter"
      >
        <div class="journal-page page-left">
          <div class="pages-content">
            <div class="chapter-portrait-wrapper">
              <img
                class="chapter-portrait flipped"
                :class="'chapter-portrait-' + chapterPage[defaultTitle]"
                :src="getLargeImg(chapterPage)"
              >
            </div>
          </div>
        </div>
        <div class="journal-page page-right">
          <div class="pages-content">
            <div class="content-inner">
              <h2>{{ chapterPage[headerTitle] }}</h2>

              <p
                v-if="chapterPage.extraInfo"
                v-html="chapterPage.extraInfo"
              />

              <p
                class="text"
                v-html="chapterPage.description"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <div
      class="grid chapters-grid"
      :class="'chapters-grid-' + chapterPages.length"
    >
      <div
        v-for="bookmark in chapterPages"
        :key="bookmark[defaultTitle]"
        :class="['hexagon avatar_small chapter-hexagon grayscale', {
          active: bookmark[defaultTitle] == activeChapterPage
        }]"
        @click="selectPage(bookmark[defaultTitle])"
      >
        <div class="hexagon-in1">
          <div
            class="hexagon-in2"
            :style="{'background-image': 'url('+getSmallImg(bookmark)+')'}"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JournalChapter',
  props: {
    chapterPages: {
      type: Array,
      default: () => []
    },
    defaultTitle: {
      type: String,
      default: ''
    },
    headerTitle: {
      type: String,
      default: ''
    },
    imgInnerPath: {
      type: String,
      default: ''
    },
    imgFullSuffix: {
      type: String,
      default: ''
    },
    imgSmallSuffix: {
      type: String,
      default: ''
    },
    largeImg: {
      type: String,
      default: ''
    },
    smallImg: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeChapterPage: ''
    }
  },
  created: function() {
    this.activeChapterPage = this.chapterPages[0][this.defaultTitle]
  },
  methods: {
    selectPage(page) {
      this.activeChapterPage = page
    },
    getLargeImg(chapterPage) {
      return this.largeImg ? chapterPage[this.largeImg] : require(`../assets/img/${this.imgInnerPath + chapterPage[this.defaultTitle] + this.imgFullSuffix}.png`)
    },
    getSmallImg(chapterPage) {
      return this.smallImg ? chapterPage[this.smallImg] : require(`../assets/img/${this.imgInnerPath + chapterPage[this.defaultTitle] + this.imgSmallSuffix}.png`)
    }
  }
}
</script>
